<template>
    <button class="text-xl" @click.prevent="navigateBack">
        <fa-icon :icon="['fas', 'chevron-left']" class="pr-2"></fa-icon>
        Back
    </button>
</template>
<script lang="ts">
import router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HeaderBackButton',
    setup() {
        const navigateBack = () => {
            console.log(window.history);
            if(window.history.length > 1){
                window.history.go(-1);
            } else {
                router.push({name:'portal'});
            }
            
        }

        return {
            navigateBack,
        }
    },

})
</script>