<template>
    <ion-app class="kl-serif">
        <portal-loading-screen 
            v-if="isLoading && !isPublicRoute" 
            :loaded="loaded"
        ></portal-loading-screen>

        <template v-if="isPublicRoute">
            <ion-router-outlet v-if="loaded" :animated="false"></ion-router-outlet>
        </template>

        <template v-if="!isPublicRoute">
            <ion-router-outlet v-if="loaded && isLoggedInPortal"  :animated="false"></ion-router-outlet>
            <portal-login-screen v-if="loaded && !isLoggedInPortal"></portal-login-screen>
        </template>
    </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { AxiosError, AxiosResponse } from 'axios';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { injectStrict } from '@/utils/injectTyped';
import { AxiosKey } from '@/symbols';
import PortalLoginScreen from '@/components/portal/PortalLoginScreen.vue';
import PortalLoadingScreen from '@/components/portal/PortalLoadingScreen.vue';
import { useRoute } from 'vue-router';
import metaData from '@/utils/scripts/metaData'
import matterStoreData from '@/utils/scripts/matterStoreData';
import externalScriptLoader from '@/utils/composables/externalScriptLoader';


export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        PortalLoginScreen,
        PortalLoadingScreen,
    },
    setup() {
        const loaded = ref(false);
        const isLoading = ref(true);
        const brand = ref('kewlaw');

        const route = useRoute();
        const isPublicRoute = computed(() => route.meta.public ? route.meta.public : false);

        const store = useStore();
        const isLoggedInPortal = computed(() => store.getters.isLoggedInPortal);

        const axios = injectStrict(AxiosKey);

        const { initMeta } = metaData();
        const { getUnreadMessages } = matterStoreData();
        const { addScript } = externalScriptLoader();

        onMounted(() => {
            initMeta();
            getUnreadMessages();
            axios.get('/api/user')
            .then((response: AxiosResponse) => {
                console.log(response);
                store.commit('setUserInfo',{
                    name: (response.data as any).name,
                    email: (response.data as any).email,
                    verified: (response.data as any).verified,
                    phone: (response.data as any).phone,
                });
                setContentState();
                store.commit('setLoggedInPortal', true);
            }).catch( (e: AxiosError) => {
                console.log(e);
                axios.get('sanctum/csrf-cookie').then(()=>{
                    setContentState();
                });
            });
        })

        const setContentState = () => {
            axios.get('/api/v1/frontend/content/general/'+brand.value)
            .then( (response: AxiosResponse) => {
                // console.log(response.data);
                // set state
                store.commit('saveContentSettings',response.data);
                loaded.value = true;
                setTimeout(() => {
                    isLoading.value = false;
                }, 1400);
            })
        }

        onMounted(() => {
            addScript('https://js.stripe.com/v3/', 'stripe-js', () => {
                console.log('stripe loaded');
            })
            addScript('https://www.yoti.com/share/client/','yoti-js',() => {
                console.log('yoti loaded');
            })
        })

        return {
            loaded,
            isLoading,
            isPublicRoute,
            brand,
            isLoggedInPortal,
        }
    }
});
</script>