<template>
    <router-link :to="'/my-matters/inbox/' + route.params.reference">
        <button class="text-white block py-4 px-6 text-center w-72 h-16 rounded bg-gray-500 flex items-center justify-center text-lg">
            <fa-icon :icon="['fal', 'envelope']" class="fa-xl pr-4"></fa-icon>
            Matters Inbox
            <div 
                v-if="unreadMessages > 0"
                class="ml-4 kl-bg__red h-7 rounded-full text-base flex justify-center items-center pt-1"
                :class="unreadMessages > 9 ? 'w-8' : 'w-7'"
            >{{unreadMessages}}</div>
        </button>
    </router-link> 
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'MatterInboxButton',
    components: {

    },
    setup() {
        const route = useRoute();
        const store = useStore();

        const unreadMessages = computed(() => store.getters.getUnreadInboxMessages);
        return {
            route,
            unreadMessages
        }
    }
})
</script>
