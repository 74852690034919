export default [
    /**
   * SERVICE FORMS
   */

    {
        path: '/service/residential-sale',
        name: 'form.res-sale',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-sale',
            sidebar: 'form_res_sale_sidebar',
            public: true,
        }
    },
    {
        path: '/service/residential-purchase',
        name: 'form.res-purchase',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-purchase',
            sidebar: 'form_res_purchase_sidebar',
            public: true,
        }
    },
    {
        path: '/service/residential-sale-and-purchase',
        name: 'form.res-sale-and-purchase',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-sale-and-purchase',
            sidebar: 'form_res_sale_purchase_sidebar',
            public: true,
        }
    },
    {
        path: '/service/residential-remortgage',
        name: 'form.res-rem',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-remortgage',
            sidebar: 'form_res_rem_sidebar',
            public: true,
        }
    },
    {
        path: '/service/residential-transfer',
        name: 'form.res-transfer',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-transfer',
            public: true,
        }
    },
    {
        path: '/service/residential-transfer-to-joint',
        name: 'form.res-transfer-joint',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-transfer-to-joint',
            sidebar: 'form_res_transfer_joint',
            public: true,
        }
    },
    {
        path: '/service/residential-transfer-to-sole',
        name: 'form.res-transfer-sole',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-transfer-to-sole',
            sidebar: 'form_res_transfer_sole',
            public: true,
        }
    },
    {
        path: '/service/residential-transfer-gift',
        name: 'form.res-transfer-gift',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-transfer-gift',
            sidebar: 'form_res_transfer_gift',
            public: true,
        }
    },
    {
        path: '/service/residential-transfer-other',
        name: 'form.res-transfer-other',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'residential-transfer-other',
            sidebar: 'form_res_transfer_other',
            public: true,
        }
    },
    {
        path: '/service/commercial-property',
        name: 'form.comm',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'commercial',
            sidebar: 'form_comm_sidebar',
            public: true,
        }
    },

    {
        path: '/service/wills',
        name: 'form.will',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'will',
            public: true,
        },
    },
    {
        path: '/service/lpa',
        name: 'form.lpa',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'LPA',
            public: true,
        },
    },
    {
        path: '/service/probate',
        name: 'form.probate',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'probate',
            public: true,
        },
    },
    {
        path: '/service/employment',
        name: 'form.employment',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'employment',
            public: true,
        },
    },
    {
        path: '/service/disputes-litigation',
        name: 'form.disputes',
        component: () => import('@/views/forms/FormPage.vue'),
        meta: {
            service: 'disputes',
            public: true,
        },
    },
    {
        path: '/thankyou',
        name: 'thanks',
        component: () => import('@/views/forms/ThankYou.vue'),
        meta: {
            public: true,
        }
    }
];