import { Module } from 'vuex';

const ResTransferFormModule: Module<ResTransferFormState, any> = {
    state: {
        transferPeople: [],
    },

    // create a list of form UIDS to be used in the form component.

    getters: {
        getTransferors(state): Array<TransferPerson> {
            return state.transferPeople.filter((person: TransferPerson) => person.isTransferor);
        },
        getTransferees(state): Array<TransferPerson> {
            return state.transferPeople.filter((person: TransferPerson) => person.isTransferee);
        },
        getAllTransferPeople(state): Array<TransferPerson> {
            return state.transferPeople;
        },
        getFirstTransfereeOnly(state): TransferPerson | any {
            const person = state.transferPeople.find((person: TransferPerson) => person.isTransferee && !person.isTransferor);
            if(person) return person;
            return {
                name: '',
            }
        }
    },

    actions: {
        clearTransferState(store) {
            store.commit('clearTransferState');
        },
        addTransferPerson(store, payload) {
            store.commit('addTransferPerson', payload);
        },
        removeTransferPerson(store, payload) {
            store.commit('removeTransferPerson', payload);
        },

    },

    mutations: {
        clearTransferState(state) {
            state.transferPeople = [];
        },
        addTransferPerson(state, payload) {
            state.transferPeople.push(payload);
        },
        removeTransferPerson(state, payload) {
            const indexOfPersonToRemove = state.transferPeople.findIndex((person: TransferPerson) => person.uid === payload);
            state.transferPeople.splice(indexOfPersonToRemove, 1);
        }
    }
}

export interface ResTransferFormState {
    transferPeople: Array<TransferPerson>;
}

export interface TransferPerson {
    name: string;
    uid: string;
    isTransferor: boolean;
    isTransferee: boolean;
    email: string;
    receivingMoney: boolean;
    amountToReceive: number;
    sharePercentage: number;
    edit: boolean;
}

export default ResTransferFormModule;