import LogRocket from 'logrocket';
if(process.env.VUE_APP_LOGROCKET_ENVIRONMENT === 'dev') {
  LogRocket.init('6hip0a/fever-kl');
  
  LogRocket.identify(process.env.VUE_APP_LOGROCKET_ENVIRONMENT, {
    name: process.env.VUE_APP_LOGROCKET_USER,
    email: 'dev@example.com',
  });
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import http from '@/http'
import { AxiosKey } from '@/symbols'

import { IonicVue } from '@ionic/vue';

import { store } from './store/index'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

import 'tailwindcss/tailwind.css'

/* Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css'
import './theme/forms.css'
import './theme/styles.css'
import './theme/portal.css'

import 'tippy.js/dist/tippy.css' // optional for styling

// fontawesome
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';

import {
  faPlusCircle,
  faPaperPlane,
  faCheckCircle,
  faPencilAlt,
  faUser,
  faChevronLeft,
  faTimesCircle,
  faTrashAlt,
  faSave,
  faPhone,
  faEnvelope as faEnvelopeSolid,
  faExclamationCircle,
  faCheck,
  faChevronRight,
  faEye,
  faDownload,
  faBusinessTime,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { 
  faTimes,
  faLongArrowLeft,
  faArrowsAlt,
  faSave as faSaveRegular,
  faFilePdf,
  faLongArrowRight,
  faSpinner,
  faSpinnerThird as faSpinnerThirdRegular,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faEnvelope,
  faHomeLgAlt,
  faInfoCircle,
  faCommentAlt,
  faPlusCircle as faPlusCircleLight,
  faSpinnerThird,
  faKey,
  faCog,
  faBook,
  faLock,
  faSignature,
  faCreditCard,
  faBallotCheck,
  faFolderOpen,
} from '@fortawesome/pro-light-svg-icons'
 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faPlusCircle as IconDefinition,
  faTimes as IconDefinition,
  faLongArrowLeft as IconDefinition,
  faPaperPlane as IconDefinition,
  faCheckCircle as IconDefinition,
  faPencilAlt as IconDefinition,
  faArrowsAlt as IconDefinition,
  faEnvelope as IconDefinition,
  faHomeLgAlt as IconDefinition,
  faUser as IconDefinition,
  faInfoCircle as IconDefinition,
  faCommentAlt as IconDefinition,
  faPlusCircleLight as IconDefinition,
  faChevronLeft as IconDefinition,
  faTimesCircle as IconDefinition,
  faTrashAlt as IconDefinition,
  faSave as IconDefinition,
  faPhone as IconDefinition,
  faEnvelopeSolid as IconDefinition,
  faExclamationCircle as IconDefinition,
  faSpinnerThird as IconDefinition,
  faCheck as IconDefinition,
  faChevronRight as IconDefinition,
  faKey as IconDefinition,
  faCog as IconDefinition,
  faBook as IconDefinition,
  faLock as IconDefinition,
  faSignature as IconDefinition,
  faCreditCard as IconDefinition,
  faBallotCheck as IconDefinition,
  faFolderOpen as IconDefinition,
  faSaveRegular as IconDefinition,
  faFilePdf as IconDefinition,
  faEye as IconDefinition,
  faDownload as IconDefinition,
  faLongArrowRight as IconDefinition,
  faSpinner as IconDefinition,
  faSpinnerThirdRegular as IconDefinition,
  faFacebookF as IconDefinition,
  faLinkedinIn as IconDefinition,
  faTwitter as IconDefinition,
  faBusinessTime as IconDefinition,
)

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false
  })
  .use(router)
  .use(store)
  .component("fa-icon",FontAwesomeIcon)
  .provide(AxiosKey, http);

export default app;
require('./_components');

router.isReady().then(() => {
  app.mount('#app');
});

