export default [
    {
        path: '/tasksthankyou',
        name: 'tasks-thanks',
        component: () => import('@/views/tasks/TasksThankYou.vue'),
        meta: {
            public: true,
        }
    },
    {
        path: '/matters/tasks/document-upload/:ref',
        name: 'document-upload',
        component: () => import('@/views/tasks/DocumentUpload.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'document_upload',
            sidebar: 'document_upload_sidebar'
        }
    },
    {
        path: '/matters/tasks/take-payment/:ref',
        name: 'take-payment',
        component: () => import('@/views/tasks/TakePayment.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'take_payment',
            sidebar: 'take_payment_sidebar'
        }
    },
    {
        path: '/matters/tasks/yoti-id/:ref',
        name: 'yoti_id',
        component: () => import('@/views/tasks/YotiCheck.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'yoti_id',
            sidebar: 'yoti_id_sidebar'
        }
    },
    {
        path: '/matters/tasks/form/sales_your_information/:ref',
        name: 'sales_your_information',
        component: () => import('@/views/tasks/forms/sales/YourInformation.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_your_information',
            sidebar: 'sales_your_information_sidebar'
        }
    },
    {
        path: '/matters/tasks/form/sales_what_are_you_selling/:ref',
        name: 'sales_what_are_you_selling',
        component: () => import('@/views/tasks/forms/sales/WhatAreYouSelling.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_what_are_you_selling',
            sidebar: 'sales_what_are_you_selling_sidebar'
        }
    },
    {
        path: '/matters/tasks/form/sales_property_information/:ref',
        name: 'sales_property_information',
        component: () => import('@/views/tasks/forms/sales/PropertyInformation.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_property_information',
            sidebar: 'sales_property_information'
        }
    },
    {
        path: '/matters/tasks/form/sales_leasehold_information/:ref',
        name: 'sales_leasehold_information',
        component: () => import('@/views/tasks/forms/sales/LeaseholdInformation.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_leasehold_information',
            sidebar: 'sales_leasehold_information'
        }
    },
    {
        path: '/matters/tasks/form/sales_financial_details/:ref',
        name: 'sales_financial_details',
        component: () => import('@/views/tasks/forms/sales/FinancialDetails.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_financial_details',
            sidebar: 'sales_financial_details'
        }
    },
    {
        path: '/matters/tasks/form/sales_tax_notice/:ref',
        name: 'sales_tax_notice',
        component: () => import('@/views/tasks/forms/sales/TaxNotice.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_tax_notice',
            sidebar: 'sales_tax_notice'
        }
    },
    {
        path: '/matters/tasks/form/sales_fittings_and_contents/:ref',
        name: 'sales_fittings_and_contents',
        component: () => import('@/views/tasks/forms/sales/FittingsAndContents.vue'),
        meta: {
            service: 'task',
            public: true,
            page:'sales_fittings_and_contents',
            sidebar: 'sales_fittings_and_contents'
        }
    }
    
]