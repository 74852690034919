import app from './main';

import MatterInboxButton from './components/portal/buttons/MatterInboxButton.vue';
import HeaderBackButton from './components/portal/buttons/HeaderBackButton.vue';
import LoadingButton from './components/portal/buttons/LoadingButton.vue';
import LoadingInner from './components/utility/LoadingInner.vue';

app.component('matter-inbox-button', MatterInboxButton);
app.component('header-back-button', HeaderBackButton);
app.component('loading-button', LoadingButton);
app.component('loading-inner', LoadingInner);