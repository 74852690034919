import { Module } from "vuex";

const PortalStateModule: Module<PortalState, any> = {
    state: {
        isLoggedIn: false,
        sideNavOpen: false,
        userInfo: {
            name: '',
            email: '',
            verified: false,
            phone:'',
            address:'not set'
        },
        tempUserInfo: {
            name: '',
            email: '',
            phone: '',
            reference: '',
        },
        dismissAccountCreate: false,
    },

    getters: {
        isLoggedInPortal(state): boolean {
            return state.isLoggedIn;
        },
        isVerifiedUser(state): boolean {
            return state.userInfo.verified;
        },
        sideNavOpen(state): boolean {
            return state.sideNavOpen;
        },
        getUserInfo(state): UserInfo {
            return state.userInfo;
        },
        hasDismissedAccCreate(state): boolean {
            return state.dismissAccountCreate;
        },
        getTempUserData(state): TempUserInfo {
            return state.tempUserInfo;
        }
    },

    actions: {
        setLoggedInPortal(store, payload) {
            store.commit('setLoggedInPortal', payload);
        },
        setSideNavState(store, payload) {
            store.commit('setSideNavState', payload);
        },
        setUserInfo(store, payload) {
            store.commit('setUserInfo', payload);
        },
        dismissAccountCreate(store) {
            store.commit('dismissAccountCreate');
        },
        setTempUserInfo(store, payload) {
            store.commit('setTempUserInfo', payload);
        }
    },

    mutations: {
        setLoggedInPortal(state, payload) {
            state.isLoggedIn = payload;
        },
        setSideNavState(state, payload) {
            state.sideNavOpen = payload;
        },
        setUserInfo(state, payload) {
            if(payload.name) state.userInfo.name = payload.name;
            if(payload.email) state.userInfo.email = payload.email;
            if(payload.verified) state.userInfo.verified = payload.verified;
            if(payload.phone) state.userInfo.phone = payload.phone;
            if(payload.address) state.userInfo.address = payload.address;
        },
        dismissAccountCreate(state) {
            state.dismissAccountCreate = true;
        },
        setTempUserInfo(state, payload) {
            if(payload.name) state.tempUserInfo.name = payload.name;
            if(payload.email) state.tempUserInfo.email = payload.email;
            if(payload.phone) state.tempUserInfo.phone = payload.phone;
            if(payload.reference) state.tempUserInfo.reference = payload.reference;
        }
    },
}

export interface PortalState {
    isLoggedIn: boolean;
    sideNavOpen: boolean;
    userInfo: UserInfo;
    dismissAccountCreate: boolean;
    tempUserInfo: TempUserInfo;
}

export interface UserInfo {
    name: string;
    email: string;
    verified: boolean;
    phone: string;
    address: string;
}

export interface TempUserInfo {
    name: string;
    email: string;
    phone: string;
    reference: string;
}

export default PortalStateModule;