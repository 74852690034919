import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal_loading_screen = _resolveComponent("portal-loading-screen")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_portal_login_screen = _resolveComponent("portal-login-screen")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { class: "kl-serif" }, {
    default: _withCtx(() => [
      (_ctx.isLoading && !_ctx.isPublicRoute)
        ? (_openBlock(), _createBlock(_component_portal_loading_screen, {
            key: 0,
            loaded: _ctx.loaded
          }, null, 8, ["loaded"]))
        : _createCommentVNode("", true),
      (_ctx.isPublicRoute)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.loaded)
              ? (_openBlock(), _createBlock(_component_ion_router_outlet, {
                  key: 0,
                  animated: false
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.isPublicRoute)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.loaded && _ctx.isLoggedInPortal)
              ? (_openBlock(), _createBlock(_component_ion_router_outlet, {
                  key: 0,
                  animated: false
                }))
              : _createCommentVNode("", true),
            (_ctx.loaded && !_ctx.isLoggedInPortal)
              ? (_openBlock(), _createBlock(_component_portal_login_screen, { key: 1 }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}