import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import { injectStrict } from '../injectTyped';
import { AxiosKey } from '@/symbols';

export default function() {
    // initiate the store and reactive variables
    const store = useStore();
    const route = useRoute();
    const axios = injectStrict(AxiosKey);

    const getUnreadMessages = () => {
        // get matter unread messages and store in state so we can use on multiple pages
        if(!route.meta.isMatterRoute) return;
        axios.get('/api/v1/portal/matter/' + route.params.reference + '/unread-messages').then((response) => {
            store.commit('setUnreadInboxMessages', response.data);
        });
    }

    // returns functions to use elsewhere
    return {
        getUnreadMessages,
    }
}