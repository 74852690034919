import { Module } from "vuex";

const MatterStateModule: Module<MatterState, any> = {
    state: {
        unreadInboxMessages: 0,
    },

    mutations: {
        setUnreadInboxMessages(state,payload) {
            state.unreadInboxMessages = payload;
        }
    },

    actions: {
        setUnreadInboxMessages(store,payload) {
            store.commit('setUnreadInboxMessages',payload);
        }
    },

    getters: {
        getUnreadInboxMessages(state): number {
            return state.unreadInboxMessages;
        }
    }
}

export interface MatterState {
    unreadInboxMessages: number;
}

export default MatterStateModule;