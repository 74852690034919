import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-white block py-4 px-6 text-center w-72 h-16 rounded bg-gray-500 flex items-center justify-center text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: '/my-matters/inbox/' + _ctx.route.params.reference
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        _createVNode(_component_fa_icon, {
          icon: ['fal', 'envelope'],
          class: "fa-xl pr-4"
        }),
        _createTextVNode(" Matters Inbox "),
        (_ctx.unreadMessages > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["ml-4 kl-bg__red h-7 rounded-full text-base flex justify-center items-center pt-1", _ctx.unreadMessages > 9 ? 'w-8' : 'w-7'])
            }, _toDisplayString(_ctx.unreadMessages), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}