import { Module } from "vuex";

const WillEditorModule: Module<Will, any> = {
    state: {
        willEditor: {
            reference: '',
            url: '',
            loaded: false,
            started: false,
            updated: 0,
            executorSettings: {
                executor_mode: '',
                executor_substitute_solicitor: true,
                executor_substitute_appoint: false,
            },
            beneficiarySettings: {
                equal_split: false,
            },
            currentWill: {},
            contactAddress: {
                address_1: '',
                city: '',
                county: '',
                country: '',
                postcode: '',
            },
            stepDeduction: 0,
        }
    },

    mutations: {
        setWillStarted(state, payload) {
            state.willEditor.started = payload;
        },
        updateWillReference(state, payload) {
            state.willEditor.reference = payload;
        },
        updateWillURL(state, payload) {
            state.willEditor.url = payload;
        },
        saveWillExecutorSettings(state, payload) {
            console.log('updating solicitor')
            console.log(payload.executor_substitute_solicitor);
            if(payload.executor_mode) state.willEditor.executorSettings.executor_mode = payload.executor_mode;
            if(
                payload.executor_substitute_solicitor === true 
                || payload.executor_substitute_solicitor === false
            ) state.willEditor.executorSettings.executor_substitute_solicitor = payload.executor_substitute_solicitor;
            if(
                payload.executor_substitute_appoint === true
                || payload.executor_substitute_appoint === false
            ) state.willEditor.executorSettings.executor_substitute_appoint = payload.executor_substitute_appoint;        
        },
        saveWillBeneficiarySettings(state, payload) {
            if(payload.equal_split === true || payload.equal_split === false) state.willEditor.beneficiarySettings.equal_split = payload.equal_split;
        },
        saveWillData(state, payload) {
            state.willEditor.currentWill = payload;
            state.willEditor.contactAddress = payload.contact_address;
        },
        updateWillContactAddress(state, payload) {
            state.willEditor.contactAddress = payload;
        },
        addAssetToWill(state, payload) {
            const type = payload.type;
            const name = payload.name;
            const details = payload.details;

            if( type === 'property') {
                const ownership = payload.ownership;
                state.willEditor.currentWill.assets[type].push({
                    name: name,
                    details: details,
                    ownership: ownership,
                });
            } else {
                state.willEditor.currentWill.assets[type].push({
                    name: name,
                    details: details,
                });
            }
        },
        removeAssetFromWill(state, payload) {
            const type = payload.type;
            const index = payload.index;

            state.willEditor.currentWill.assets[type].splice(index, 1);
        },
        updatePropertyAsset(state, payload) {
            const index = payload.index;
            const address = payload.address;

            state.willEditor.currentWill.assets.property[index].address = address;
        },
        addWillGift(state) {
            state.willEditor.currentWill.gifts.push({
                type: '',
                description: '',
                for: '',
            });
        },
        removeWillGift(state, payload: number) {
            state.willEditor.currentWill.gifts.splice(payload, 1);
        },
        setStepDeduction(state, payload: number) {
            state.willEditor.stepDeduction = payload;
        },
        updateWillFuneral(state,payload) {
            state.willEditor.currentWill.funeral = payload;
        }
    },

    actions: {
        setWillStarted(store, payload) {
            store.commit('setWillStarted', payload);
        },
        updateWillReference(store, payload) {
            store.commit('updateWillReference', payload);
        },
        updateWillURL(store, payload) {
            store.commit('updateWillURL', payload);
        },
        saveWillExecutorSettings(store, payload) {
            store.commit('saveWillExecutorSettings', payload);
        },
        saveWillBeneficiarySettings(store, payload) {
            store.commit('saveWillBeneficiarySettings', payload);
        },
        saveWillData(store, payload) {
            store.commit('saveWillData', payload);
        },
        updateWillContactAddress(store, payload) {
            store.commit('updateWillContactAddress', payload);
        },
        addAssetToWill(store, payload: any) {
            store.commit('addAssetToWill', payload);
        },
        updatePropertyAsset(store, payload: any) {
            store.commit('updatePropertyAsset', payload);
        },
        addWillGift(store) {
            store.commit('addWillGift');
        },
        removeWillGift(store, payload: number) {
            store.commit('removeWillGift', payload);
        },
        setStepDeduction(store, payload: number) {
            store.commit('setStepDeduction', payload);
        },
        updateWillFuneral(store, payload) {
            store.commit('updateWillFuneral', payload)
        }
    },

    getters: {
        isWillJustStarted(state) {
            return state.willEditor.started;
        },
        isWillLoaded(state): boolean {
            return state.willEditor.loaded;
        },
        getWillState(state): any {
            return state.willEditor.currentWill;
        },
        getWillReference(state): string {
            return state.willEditor.reference;
        },
        getWillExecutorSettings(state): any {
            return state.willEditor.executorSettings;
        },
        getWillBeneficiarySettings(state): any {
            return state.willEditor.beneficiarySettings;
        },
        getWillContactAddress(state): any {
            return state.willEditor.contactAddress;
        },
        getStepDeduction(state): number {
            return state.willEditor.stepDeduction;
        },
        getWillAssets(state): any {
            return state.willEditor.currentWill?.assets;
        },
        getWillFlags(state): any {
            return state.willEditor.currentWill?.flags
        },
        getWillGifts(state): any {
            return state.willEditor.currentWill?.gifts
        },
        getWillFuneral(state): any {
            return state.willEditor.currentWill?.funeral
        },
        getHomeOwnerStatus(state): any{
            return state.willEditor.currentWill?.is_homeowner
        }
    },

}

export interface Will {
    willEditor: {
        reference: string;
        url: string;
        loaded: boolean;
        started: boolean;
        updated: number;
        executorSettings: {
            executor_mode: string;
            executor_substitute_solicitor: boolean,
            executor_substitute_appoint: boolean,
        };
        beneficiarySettings: {
            equal_split: boolean;
        };
        currentWill: any;
        contactAddress: {
            address_1: string
            city: string
            county: string
            country: string
            postcode: string
        },
        stepDeduction: number,
    }
}

export default WillEditorModule;