import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "text-xl",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args)), ["prevent"]))
  }, [
    _createVNode(_component_fa_icon, {
      icon: ['fas', 'chevron-left'],
      class: "pr-2"
    }),
    _createTextVNode(" Back ")
  ]))
}