import { InjectionKey } from 'vue'
import { 
    createStore, 
    useStore as baseUseStore, 
    Store,
    GetterTree,
    MutationTree,
    ActionTree,
} from 'vuex'

// modules
import peopleDirectoryModule from './peopleDirectory'
import willEditorModule from './WillEditorModule'
import LPABuilderModule from './LPABuilderModule'
import ResTransferFormModule from './ResTransferFormModule'
import FrontendFormsModule from './FrontendFormsModule'
import PortalStateModule from './PortalStateModule'
import PageMetaModule from './PageMetaModule'
import MatterStateModule from './MatterStateModule'

export interface ContentSettings {
    contact_phone: string;
    site_url: string;
}

export interface State {
    contentSettings: ContentSettings;
    unsavedChanges: boolean;
    enquiryref: string;
    messages: {
        error: string;
        success: string;
    }
}

export const key: InjectionKey<Store<State>> = Symbol();
const state: State = {
    contentSettings: {
        contact_phone: '',
        site_url: '',
    },
    unsavedChanges: false,
    enquiryref: '',
    messages: {
        error: '',
        success: '',
    },
};

/**
 * Getters
 */
const getters: GetterTree<State, any> = {
    generalContent(state): ContentSettings {
        return state.contentSettings;
    },
    unsavedChanges(state): boolean {
        return state.unsavedChanges;
    },
    getEnquiryReference(state): string {
        return state.enquiryref;
    },
}

/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */
const mutations: MutationTree<State> = {
    saveContentSettings(state, payload) {
        state.contentSettings.contact_phone = payload.contact_phone;
        state.contentSettings.site_url = payload.site_url;
    },
    unsavedChanges(state, payload) {
        state.unsavedChanges = payload;
    },
    saveEnquiryReference(state, payload) {
        state.enquiryref = payload;
    },
    setError(state, payload: string) {
        state.messages.error = payload;
        setTimeout(() => {
            state.messages.error = '';
        }, 2000);
    },
}

/*
 * Actions
 * Perform async tasks, then mutate state
 */
const actions: ActionTree<State, any> = {
    
    saveContentSettings(store, payload) {
        store.commit('saveContentSettings', payload);
    },
    changeMade(store, payload = true) {
        store.commit('unsavedChanges', payload);
    },
    saveEnquiryReference(store, payload = '') {
        store.commit('saveEnquiryReference', payload);
    },
    setError(store, payload) {
        store.commit('setError', payload);
    },
}

export const store = createStore<State>({ state, mutations, actions, getters, modules: {
    peopleDirectory: peopleDirectoryModule,
    willEditorModule: willEditorModule,
    ResTransferFormModule: ResTransferFormModule,
    FrontendFormsModule: FrontendFormsModule,
    PortalStateModule: PortalStateModule,
    LPABuilderModule: LPABuilderModule,
    PageMetaModule: PageMetaModule,
    MatterStateModule: MatterStateModule
} });

// our own `useStore` composition function for types
export function useStore() {
    return baseUseStore(key);
}


