import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import miscRoutes from './miscRoutes';
import portalRoutes from './portalRoutes';
import serviceFormRoutes from './serviceFormRoutes';
import taskRoutes from './taskRoutes';
import willBuilderRoutes from './willBuilderRoutes';

const routes: Array<RouteRecordRaw> = [
    ...portalRoutes,
    ...miscRoutes,
    ...serviceFormRoutes,
    ...willBuilderRoutes,
    ...taskRoutes,

    { 
        path: '/:pathMatch(.*)*', 
        name: 'not-found', 
        component: import('@/views/NotFound.vue'), 
        meta: {
            public: true,
        } 
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
