export default [
    
    {
        path: '/my-will/edit/:url/:reference',
        name: 'will-builder.home',
        component: () => import('@/views/willbuilder/BuilderHome.vue'),
        meta: {
            service: 'will',
            public: true,
            useDeduction: true,
        },
    },

    // my details section
    {
        path: '/my-will/edit/:url/:reference/my-details',
        name: 'will-builder.details',
        component: () => import('@/views/willbuilder/MyDetails.vue'),
        meta: {
            service: 'will',
            public: true,
            to: 'will-builder.home',
            sidebar: 'details_sidebar',
            step: 1,
            useDeduction: false,
        },
    },

    // partner details section
    {
        path: '/my-will/edit/:url/:reference/partner-details',
        name: 'will-builder.partner-details',
        component: () => import('@/views/willbuilder/PartnerDetails.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'partner_sidebar',
            to: 'will-builder.home',
            step: 2,
            useDeduction: false,
        },
    },

    // children section
    {
        path: '/my-will/edit/:url/:reference/children',
        name: 'will-builder.children',
        component: () => import('@/views/willbuilder/Children.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'children_sidebar',
            to: 'will-builder.home',
            step: 3,
            useDeduction: true,
        },
    },

    // about guardians section
    {
        path: '/my-will/edit/:url/:reference/about-guardians',
        name: 'will-builder.about-guardians',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about_guardians',
            sidebar: 'about_guardians_sidebar',
            to: 'will-builder.guardians',
            shortTitle: 'Guardians',
            longTitle: 'About Guardians',
            step: 4,
            useDeduction: true,
        },
    },

    // guardians section
    {
        path: '/my-will/edit/:url/:reference/guardians',
        name: 'will-builder.guardians',
        component: () => import('@/views/willbuilder/Guardians.vue'),
        meta: {
            service: 'will',
            public: true,
            to: 'will-builder.home',
            backTo: 'will-builder.guardians',
            sidebar: 'guardians_sidebar',
            step: 4,
            useDeduction: true,
        },
    },

    // about beneficiaries section
    {
        path: '/my-will/edit/:url/:reference/about-beneficiaries',
        name: 'will-builder.about-beneficiaries',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about_beneficiaries',
            sidebar: 'about_beneficiaries_sidebar',
            to: 'will-builder.beneficiaries',
            shortTitle: 'Beneficiaries',
            longTitle: 'Your beneficiaries',
            step: 5,
            useDeduction: true,
        },
    },

    // beneficiaries section
    {
        path: '/my-will/edit/:url/:reference/beneficiaries',
        name: 'will-builder.beneficiaries',
        component: () => import('@/views/willbuilder/Beneficiaries.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'beneficiaries_sidebar',
            to: 'will-builder.about-executors',
            backTo: 'will-builder.beneficiaries',
            step: 5,
            useDeduction: true,
        },
    },

    // about executors section
    {
        path: '/my-will/edit/:url/:reference/about-executors',
        name: 'will-builder.about-executors',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about_executors',
            sidebar: 'about_executors_sidebar',
            to: 'will-builder.appointing-a-solicitor',
            backTo: 'will-builder.home',
            step: 6,
            useDeduction: true,
        },
    },

    // appointing a solicitor executor section
    {
        path: '/my-will/edit/:url/:reference/appointing-a-solicitor',
        name: 'will-builder.appointing-a-solicitor',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'appointing_a_solicitor',
            sidebar: 'appointing_a_solicitor_sidebar',
            to: 'will-builder.executors-mode',
            backTo: 'will-builder.about-executors',
            step: 6,
            useDeduction: true,
        },
    },

    // executors section
    {
        path: '/my-will/edit/:url/:reference/choosing-an-executor',
        name: 'will-builder.executors-mode',
        component: () => import('@/views/willbuilder/ExecutorsChoosing.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'choosing_an_executor_sidebar',
            to: 'will-builder.executors',
            backTo: 'will-builder.appointing-a-solicitor',
            step: 6,
            useDeduction: true,
        },
    },

    // appointing people
    {
        path: '/my-will/edit/:url/:reference/appointing-an-executor',
        name: 'will-builder.executors',
        component: () => import('@/views/willbuilder/Executors.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'executors_sidebar',
            to: 'will-builder.substitute-executors',
            backTo: 'will-builder.executors-mode',
            step: 6,
            useDeduction: true,
        },
    },

    // substitute executor section
    {
        path: '/my-will/edit/:url/:reference/substitute-executors',
        name: 'will-builder.substitute-executors',
        component: () => import('@/views/willbuilder/ExecutorsSubstitute.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'substitute_executors_sidebar',
            to: 'will-builder.about-assets.',
            backTo: 'will-builder.executors',
            step: 6,
            useDeduction: true,
        },
    },

    // about assets section
    {
        path: '/my-will/edit/:url/:reference/about-assets',
        name: 'will-builder.about-assets',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about_assets',
            sidebar: 'about_assets_sidebar',
            to: 'will-builder.assets',
            step: 7,
            useDeduction: true,
        },
    },

    // assets section
    {
        path: '/my-will/edit/:url/:reference/assets',
        name: 'will-builder.assets',
        component: () => import('@/views/willbuilder/Assets.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'assets_sidebar',
            to: 'will-builder.home',
            backTo: 'will-builder.about-assets',
            step: 7,
            useDeduction: true,
        },
    },

    // about gifts
    {
        path: '/my-will/edit/:url/:reference/about-gifts',
        name: 'will-builder.about-gifts',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about_gifts',
            sidebar: 'about_gifts_sidebar',
            to: 'will-builder.gifts',
            step: 0,
            useDeduction: false,
        },
    },

    // gifts section
    {
        path: '/my-will/edit/:url/:reference/gifts',
        name: 'will-builder.gifts',
        component: () => import('@/views/willbuilder/Gifts.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'gifts_sidebar',
            to: 'will-builder.home',
            step: 0,
            useDeduction: false,
        },
    },

    // about funeral
    {
        path: '/my-will/edit/:url/:reference/about-funeral',
        name: 'will-builder.about-funeral',
        component: () => import('@/views/willbuilder/AboutPage.vue'),
        meta: {
            service: 'will',
            public: true,
            page: 'about-funeral',
            sidebar: 'about_funeral_sidebar',
            to: 'will-builder.funeral',
            step: 0,
            useDeduction: false,
        },
    },

    // funeral section
    {
        path: '/my-will/edit/:url/:reference/funeral',
        name: 'will-builder.funeral',
        component: () => import('@/views/willbuilder/Funeral.vue'),
        meta: {
            service: 'will',
            public: true,
            sidebar: 'funeral_sidebar',
            to: 'will-builder.home',
            backTo: 'will-builder.funeral',
            step: 0,
            useDeduction: false,
        },
    },

    // final summary
    {
        path: '/my-will/edit/:url/:reference/summary',
        name: 'form.will.submission.summary',
        component: () => import('@/views/willbuilder/Summary.vue'),
        meta: {
            service: 'will',
            public: true,
        },
    },

    // payment screen
    {
        path: '/my-will/edit/:url/:reference/payment',
        name: 'form.will.submission.payment',
        component: () => import('@/views/willbuilder/Payment.vue'),
        meta: {
            service: 'will',
            public: true,
        },
    },

    // payment success
    {
        path: '/my-will/edit/:url/:reference/payment/next',
        name: 'form.will.submission.payment.next',
        component: () => import('@/views/willbuilder/PaymentSuccess.vue'),
        meta: {
            service: 'will',
            public: true,
        },
    }
]