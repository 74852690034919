import { Module } from "vuex";

const FrontendFormsModule: Module<Forms, any> = {
    state: {
        forms: [],
    },

    mutations: {
        saveForm(state, payload: Form) {
            // if a form of the same type already exists overwrite it
            // let existingFormOfSameType = state.forms.find( (form: Form) => {form.type === payload.type});
            const existingFormIndex = state.forms.findIndex( (form: Form) => form.type === payload.type); 
            if(existingFormIndex != -1) {
                state.forms.splice(existingFormIndex, 1);
            }
            state.forms.push(payload);
        },
    },

    actions: {
        saveForm(store, payload) {
            const questions: any = [];
            for (const section in payload.form) {
                for (const question in payload.form[section].qs) {
                    questions.push({
                        id: payload.form[section].qs[question].id,
                        value: payload.form[section].qs[question].value,
                        label: payload.form[section].qs[question].label,
                    } as Question)
                }
            }
    
            store.commit('saveForm', {
                type: payload.type,
                questions: questions,
                name: payload.name,
                email: payload.email,
                phone: payload.phone,
            });
        },
    },

    getters: {
        getForms(state): Form[] {
            return state.forms;
        },
    }
}

export interface Forms {
    forms: Form[];
}

export interface Form {
    type: string;
    questions: any;
    name: string;
    email: string;
    phone: string;
}

export interface Question {
    id: string;
    value: string;
    label: string;
}

export default FrontendFormsModule;