import { Module } from "vuex";
import { Address, DOB } from "./peopleDirectory";

const ExampleModule: Module<LPA, any> = {
    state: {
        lpa: {
            formType: '',
            contactDetails: {
                title: '',
                firstNames: '',
                lastNames: '',
                email: '',
                phone: '',
                dob: {
                    d: '',
                    m: '',
                    y: ''
                },
                address: {
                    address_1: '',
                    city: '',
                    county: '',
                    country: '',
                    postcode: '',
                },
            },
            questions: {
                donor_title: '',
                donor_first_name: '',
                donor_last_name: '',
                donor_dob: {d: '', m: '', y: ''},
                donor_address: {
                    address_1: '', 
                    city: '', 
                    county: '', 
                    country: '', 
                    postcode: ''
                },
                donor_email: '',
                life_sustaining_decision: '',
                when_lpa_used: '',
                who_are_attorneys: '',
                attorney_list: [],
                trust_company: {
                    name: '',
                    address: {
                        address_1: '',
                        city: '',
                        county: '',
                        country: '',
                        postcode: '',
                    },
                    email: '',
                    registrationNumber: '',
                },
                multiple_attorney_decisions: '',
                multiple_attorney_decision_details: '',
                donor_replacement_attorneys: '',
                donor_replacement_attorneys_list: [],
                donor_replacement_attorneys_trust_company: {
                    name: '',
                    address: {
                        address_1: '',
                        city: '',
                        county: '',
                        country: '',
                        postcode: '',
                    },
                    email: '',
                    registrationNumber: '',
                },
                replacement_attorney_step_in: '',
                replacement_attorney_step_in_details: '',
                replacement_attorney_decisions: '',
                replacement_attorney_decisions_details: '',
                certificate_provider: '',
                alternate_certificate_provider: {
                    title: '',
                    firstNames: '',
                    lastNames: '',
                    email: '',
                    phone: '',
                    dob: {d: '', m: '', y: ''},
                    address: {
                        address_1: '',
                        city: '',
                        county: '',
                        country: '',
                        postcode: '',
                    },

                },
                notified_people: [],
                preferences: '',
                instructions: '',
                registered_by: '',
                correspondance_preferences: '',
            }
        }
    },

    mutations: {
        setLPAFormType(state) {
            state.lpa.formType;
        }
    },

    actions: {
        setLPAFormType(store) {
            store.commit('setLPAFormType');
        }
    },

    getters: {
        getLPAFormType(state): string {
            return state.lpa.formType;
        }
    }
}

export interface LPA {
    lpa: {
        formType: string;
        contactDetails: LPAPerson;
        questions: {
            donor_title: string;
            donor_first_name: string;
            donor_last_name: string;
            donor_dob: DOB;
            donor_address: Address;
            donor_email: string;

            life_sustaining_decision: string;
            when_lpa_used: '' | 'mental_capacity' | 'when_registered';

            who_are_attorneys: string;
            attorney_list: LPAPerson[],
            trust_company: Company,

            multiple_attorney_decisions: string;
            multiple_attorney_decision_details: string;

            donor_replacement_attorneys: string;
            donor_replacement_attorneys_list: LPAPerson[],
            donor_replacement_attorneys_trust_company: Company,

            replacement_attorney_step_in: string;
            replacement_attorney_step_in_details: string;

            replacement_attorney_decisions: string;
            replacement_attorney_decisions_details: string;

            certificate_provider: string;
            alternate_certificate_provider: LPAPerson;

            notified_people: LPAPerson[];

            preferences: string;
            instructions: string;

            registered_by: string;
            correspondance_preferences: string;
        }
    }
}

export interface LPAPerson {
    title: string;
    firstNames: string;
    lastNames: string;
    email: string;
    phone: string;
    dob: DOB;
    address: Address;
}

export interface Company {
    name: string;
    address: Address;
    registrationNumber: string;
    email: string;
}


export default ExampleModule;