<template>
    <ion-page>
        <div class="grid h-full w-full grid-cols-12 overflow-hidden">
            <div class="hidden col-span-12 md:col-span-6 md:flex flex-col justify-center items-center space-y-36 relative login-bg">
                <svg xmlns="http://www.w3.org/2000/svg" class="absolute top-0 right-0 h-full w-auto transform translate-x-full" viewBox="0 0 114.9 1440" xml:space="preserve">
                    <path d="M0 1440V0l28.7 120c28.7 120 86.2 360 86.2 600s-57.5 480-86.2 600L0 1440z" fill="#E7E9EC"/>
                </svg>
                
                <div class="max-w-lg text-center space-y-8">
                    <h1 class="text-xl md:text-2xl kl-serif max-w-xs mx-auto">All of your legal matters in one place</h1>
                    <p class="text-sm lg:text-base kl-blue kl-serif-regular mx-auto">At the heart of Kew Law is the aim to make our services as seamless, accessible, and transparent as possible for our clients.</p>
                </div>
                <img src="/assets/img/login-image.svg" alt="login-logo">
            </div>
            <div class="col-span-12 md:col-span-6 py-20 px-4 max-h-screen overflow-y-scroll flex flex-col justify-center items-center">
                <form @submit.prevent="login" class="w-full sm:w-96">
                    <div class="px-4 lg:px-8 w-60 pb-20 mx-auto">
                        <!-- <figure>Logo</figure> -->
                        <kewlaw-sidebar-logo></kewlaw-sidebar-logo>
                        <img src="/assets/img/lawManagerLogo.svg" class="pt-2" alt="law-manager-logo">
                    </div>
                    <h2 class="text-center my-10">Login to your account</h2>

                    <p class="text-center py-4 kl-red" v-if="errorText">{{errorText}}</p>

                    <ion-label>Your email</ion-label>
                    <ion-item lines="none" class="mb-6 mt-2">
                        <ion-input
                            type="email"
                            placeholder="Email"
                            v-model="email"
                        ></ion-input>
                    </ion-item>

                    <ion-label>Your password</ion-label>
                    <ion-item lines="none" class="mb-6 mt-2">
                        <ion-input
                            type="password"
                            placeholder="Password"
                            v-model="password"
                            minlength="8"
                        ></ion-input>
                    </ion-item>

                    <p class="mt-3">
                        <a href="/forgot-password" class="text-sm underline">Forgot password</a>
                    </p>
                    <p class="mt-3">
                        <a href="/register" class="text-sm underline">Don't have an account? Register here</a>
                    </p>

                    <ion-button
                        type="submit"
                        expand="block"
                        class="mt-7"
                    >Login</ion-button>

                    <button class="hidden" type="submit"></button>

                </form>
            </div>
        </div>
    </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import {
    IonPage,
    IonLabel,
    IonItem,
    IonInput,
    IonButton,
} from '@ionic/vue';
import { injectStrict } from '@/utils/injectTyped';
import { AxiosKey } from '@/symbols';
import KewlawSidebarLogo from './svg/KewlawSidebarLogo.vue';

export default defineComponent({
    name: 'PortalLoginScreen',
    components: {
        IonPage,
        IonLabel,
        IonItem,
        IonInput,
        IonButton,
        KewlawSidebarLogo,
    },
    setup() {
        const axios = injectStrict(AxiosKey);
        const email = ref('');
        const password = ref('');
        const errorText = ref('');
        const login = async () => {
            if (email.value.length > 0 && password.value.length > 0) {
                errorText.value = '';
                await axios.post('/api/v1/accounts/start', {
                    email: email.value,
                    password: password.value,
                }, {
                    withCredentials: true,
                }).then(() => {
                    window.location.reload();
                }).catch(() => {
                    errorText.value = 'Invalid credentials';
                });
            } else {
                errorText.value = 'Please enter your email and password';
            }
        }

        return {
            login,
            errorText,
            email,
            password,
        }
    }
})
</script>
