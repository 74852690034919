import { Module } from "vuex";

const peopleDirectoryModule: Module<PeopleDirectory, any> = {
    state: {
        peopleDirectory: [],
        peopleDirectoryUpdated: 0,
        peopleDirectoryLoaded: false,
    },

    getters: {
        getWillPeople(state): Person[] {
            return state.peopleDirectory;
        },
        peopleUpdated(state): number {
            return state.peopleDirectoryUpdated;
        },
        getWillPartner(state): Person | boolean {
            const partner = state.peopleDirectory.find(p => p.relation === 'partner');
            return partner ? partner : false;
        },
        getWillAdults(state): Person[] {
            return state.peopleDirectory.filter(p => p.relation !== 'child' && p.over_eighteen).sort((a, b) => a.executor_priority < b.executor_priority ? -1 : 1);
        },
        getWillChildren(state): Person[] {
            return state.peopleDirectory.filter(p => p.relation === 'child');
        },
        getWillExecutors(state): Person[] {
            return state.peopleDirectory.filter(p => p.executor).sort((a, b) => a.executor_priority < b.executor_priority ? -1 : 1);
        },
        getPotentialWillSubstituteExecutors(state): Person[] {
            return state.peopleDirectory.filter(p => p.relation !== 'child' && !p.executor ).sort((a, b) => a.executor_priority < b.executor_priority ? -1 : 1);
        },
        getSubstituteExecutors(state): Person[] {
            return state.peopleDirectory.filter(p => p.is_substitute_executor).sort((a, b) => a.executor_priority < b.executor_priority ? -1 : 1);
        },
        getWillBeneficiaries(state): Person[] {
            return state.peopleDirectory.filter(p => p.beneficiary);
        },
    },

    actions: {
        updatePeopleDirectory(store, payload) {
            store.commit('updatePeopleDirectory', payload);
        },
        mergePeopleDirectory(store, payload) {
            store.commit('mergePeopleDirectory', payload);
        },
        addPersonToPeopleDirectory(store, payload) {
            store.commit('addPersonToPeopleDirectory', payload);
        },
        removePersonFromPeopleDirectory(store, payload) {
            store.commit('removePersonFromPeopleDirectory', payload);
        },
        removePersonAsBeneficiary(store, payload) {
            store.commit('removePersonAsBeneficiary', payload);
        },
        updateExecutorPriorities(store, payload) {
            store.commit('updateExecutorPriorities', payload);
        },
        updateSubstituteExecutorToggle(store, payload) {
            store.commit('updateSubstituteExecutorToggle', payload);
        },
        updateBeneficiaryToggle(store, payload) {
            store.commit('updateBeneficiaryToggle', payload);
        },
        updateWillPerson(store, payload) {
            store.commit('updateWillPerson', payload);
        }
    },

    mutations: {
        updatePeopleDirectory(state, payload) {
            state.peopleDirectory = payload;
            state.peopleDirectoryLoaded = true;
            state.peopleDirectoryUpdated++;
        },
        mergePeopleDirectory(state, payload) {
            if(payload.length > 0) {
                payload.forEach((p: Person) => {
                    const existingPerson = state.peopleDirectory.find(person => person.uid === p.uid);
                    if(existingPerson) {
                        Object.assign(existingPerson, p);
                    }
                });
            }
        },
        addPersonToPeopleDirectory(state, payload) {
            const person = {
                full_name: '',
                relation: 'additional',
                uid: Date.now().toString(),
                beneficiary: false,
                executor: false,
                is_substitute_executor: false,
                executor_priority: 0,
                entitlement: 0,
                fallback_entitlement: '',
                fallback_people: [],
                children_to_inherit: false,
                email: '',
                postal: {
                    address_1: '',
                    city: '',
                    county: '',
                    postcode: '',
                    country: '',
                },
                over_eighteen: true,
                guardians: {
                    first: {
                        is_parent: false,
                        uid: '',
                    },
                    second: {
                        is_parent: false,
                        uid: '',
                    },
                },
                dob: {d: null, m: null, y: null},
                equal_split: false,
            }
            state.peopleDirectory.push({...person, ...payload});
            state.peopleDirectoryUpdated++;
        },
        removePersonFromPeopleDirectory(state, payload) {
            const existingPersonIndex = state.peopleDirectory.findIndex( (person: Person) => person.uid === payload);
            console.log('existing person index',existingPersonIndex);
            
            if(existingPersonIndex != -1) {
                // remove person from people directory
                state.peopleDirectory.splice(existingPersonIndex, 1);
                // loop through the people
                state.peopleDirectory.forEach(person => {
                    // remove the person from any guardians
                    if(person.guardians.first.uid === payload) {
                        person.guardians.first.uid = '';
                        person.guardians.first.is_parent = false;
                    }

                    if(person.guardians.second.uid === payload) {
                        person.guardians.second.uid = '';
                        person.guardians.second.is_parent = false;
                    }

                    // remove the person from any fallback people
                    const existingFallbackIndex = person.fallback_people.findIndex( (fallback: any) => fallback.uid === payload);
                    if(existingFallbackIndex != -1) {
                        person.fallback_people.splice(existingFallbackIndex, 1);
                    }
                });
            }
            state.peopleDirectoryUpdated++;
        },
        updateWillPerson(state, payload) {
            const existingPersonIndex = state.peopleDirectory.findIndex( (person: Person) => person.uid === payload.uid);
            if(existingPersonIndex !== -1) {
                state.peopleDirectory[existingPersonIndex] = {...state.peopleDirectory[existingPersonIndex], ...payload};
            }
        },
        removePersonFromExecutor(state, payload: number | string) {
            const existingPersonIndex = state.peopleDirectory.findIndex( (person: Person) => person.uid === payload);
            if(existingPersonIndex != -1) {
                state.peopleDirectory[existingPersonIndex].executor = false;
            }
            state.peopleDirectoryUpdated++;
        },
        removePersonFromSubstituteExecutor(state, payload: number | string) {
            const existingPersonIndex = state.peopleDirectory.findIndex( (person: Person) => person.uid === payload);
            if(existingPersonIndex != -1) {
                state.peopleDirectory[existingPersonIndex].is_substitute_executor = false;
            }
        },
        removePersonAsBeneficiary(state, payload: number | string) {
            const existingPersonIndex = state.peopleDirectory.findIndex( (person: Person) => person.uid === payload);
            if(existingPersonIndex != -1) {
                state.peopleDirectory[existingPersonIndex].beneficiary = false;
            }
        },
        updateExecutorPriorities(state, payload: Person[]) {
            if(state.peopleDirectory.length > 0 && payload.length > 0) {
                payload.forEach((person: any, index: number) => {
                    const existingPersonIndex = state.peopleDirectory.findIndex(p => p.uid === person.uid);
                    if( existingPersonIndex !== -1 ) {
                        state.peopleDirectory[existingPersonIndex].executor_priority = index;
                    }
                });
            }
        },
        updateSubstituteExecutorToggle(state, payload: number | string) { 
            const existingPersonIndex = state.peopleDirectory.findIndex(p => p.uid === payload);
            if(existingPersonIndex !== -1) {
                state.peopleDirectory[existingPersonIndex].is_substitute_executor = !state.peopleDirectory[existingPersonIndex].is_substitute_executor;
            }
        },
    },

}

export interface PeopleDirectory {
    peopleDirectory: Person[];
    peopleDirectoryUpdated: number;
    peopleDirectoryLoaded: boolean;
}

export interface Person {
    full_name: string;
    relation: string;
    uid: string;
    beneficiary: boolean;
    entitlement: number;
    executor: boolean;
    is_substitute_executor: boolean;
    executor_priority: number;
    fallback_entitlement: string;
    equal_split: boolean;
    fallback_people: {
        uid: string;
        amount: number;
    }[];
    children_to_inherit: boolean;
    email: string;
    postal: Address;
    over_eighteen: boolean;
    guardians: {
        first: Guardian;
        second: Guardian;
    };
    dob: DOB;
}

export interface Address {
    address_1: string;
    city: string;
    county: string;
    postcode: string;
    country: string;
}

export interface DOB {
    d: string | number;
    m: string | number;
    y: string | number;
}

export interface Guardian {
    is_parent: boolean;
    uid: string | number;
}

export default peopleDirectoryModule;