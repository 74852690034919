<template>
    <div
        class="loading fixed top-0 bottom-0 left-0 right-0 kl-bg__red transition-opacity flex justify-center items-center"
        :class="{'loaded': loaded}"
    >
        <div class="max-w-sm">
            <kewlaw-loading-logo></kewlaw-loading-logo>
            <img src="/assets/img/lawManagerLogo-white.svg" alt="law-manager-logo">
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import KewlawLoadingLogo from '@/components/portal/svg/KewlawLoadingLogo.vue';

export default defineComponent({
    name: 'PortalLoadingScreen',
    components: {
        KewlawLoadingLogo,
    },
    props: {
        loaded: {
            type: Boolean,
            default: false
        }
    }
})
</script>

<style scoped>
.loading {
    z-index: 1000;
}

.loaded {
    animation: loadfade;
    animation-duration: 1.5s;
}

@keyframes loadfade {
    from {
        opacity: 100%;
        pointer-events: none;
    }

    75% {
        opacity: 100%;
        pointer-events: none;
    }

    to {
        opacity: 0%;
        pointer-events: auto;
    }
}
</style>
